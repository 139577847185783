.accordionSubTitle {
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(84, 84, 84);
  align-items: center;
  margin-right: 1em !important;
  margin-left: 1em !important;
  margin-top: 2em !important;
}

.accordionSubTitle::before,
.accordionSubTitle::after {
  content: "";
  flex: 1;
  height: 1px;
  background: #E0E0E0;
}

.right .MuiTimelineItem-missingOppositeContent:before {
  content: none !important
}

.scrollAccordion {
  scroll-margin-top: 80px;
}

.editableModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 400px !important;
  background-color: #fff !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
  padding-top: 2em !important;
  padding-right: 2em !important;
  padding-left: 2em !important;
  padding-bottom: 2em !important;
}

.link {
  color: #1976d2!important;
  text-decoration-color: rgba(25, 118, 210, 0.4)!important;
}


.fs14{
	font-size: 14px;
}

.mtpx8{
  margin-top: 8px !important;
}