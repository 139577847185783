.k-grid {
  line-height: 1 !important;
}

.k-grid-header-wrap table[role=presentation],
table[role=presentation].k-grid-table {
	min-width: 100% !important;
}

.k-grid-lg {
  min-height: calc(100vh - 170px) !important;
}

.k-grid-with-caption {
  min-height: calc(100vh - 223px) !important;
}

.k-grid-lg th,.k-grid-lg td {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.k-grid-lg .k-master-row th,.k-grid-lg .k-master-row td {
  padding: 5px !important;
}

.k-grid-sm th,.k-grid-sm td {
  font-size: 0.75rem !important;
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.k-grid-sm .k-master-row th,.k-grid-sm .k-master-row td {
  padding: 0.5rem !important;
}

.k-grid-header-sticky {
  border: none !important;
}