.right-buttons-header {
	margin-left: auto;
}

.td-table .k-input-spinner.k-spin-button {
	display: none !important;
	background-color: #FBFBFB;
}

.td-table input {
	height: 30px;
}

.toolbar-right-btns {
	text-align: right;
	float: right;
	margin-left: auto;
}

td.k-grid-edit-cell.td-table {
	padding: 6px 10px !important;
}
